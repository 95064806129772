import { Button, Checkbox, FormControl, Hidden, IconButton, Input, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import TuneIcon from '@mui/icons-material/Tune';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import TradeRow from "./TradeRow";
import { MenuContext, TradesContext } from "../App";
import { convertDateToRelevant, isNumericString, translateCategoryToDaySwing, translateStatusToText, translateTypeToText, translateUserIdToName } from "../lib/helpFunc";
import { createChart } from 'lightweight-charts';
import { LinechartComponent } from "./LinechartComponent";
import { changeTrade, closeTrade, deleteEvent, deleteTrade, fillTrade, followTrade, getAvanzaChartDataViaProxy, getLivePriceOnSearchedSymbol, unfollowTrade } from "../lib/network";
import Tooltip from '@mui/material/Tooltip';

const toShortenedText = (text) => {
	if (text.length > 25) {
		return text.substring(0, 22) + "..."
	}
	return text
}

const handleEditEvent = (event, setSelectedEvent, setSelectedTrade, setIsEditEventMenuOpen) => {
	console.log("attempting to edit event")
	console.log(event)
	setSelectedEvent(event)
	setSelectedTrade(null)
	setIsEditEventMenuOpen(true)
}

const handleDeleteEvent = async (event, setSelectedTrade) => {
	console.log("attempting to delete event")
	console.log(event)
	try {
		if (event.type === "open") {
			if (window.confirm("Genom att stänga öppningen så kommer du ta bort hela traden, är du säker på att du vill ta bort hela traden?")) {
				console.log('Removing trade');
				const result = await deleteTrade(event.trade_id)
				console.log(result)
				if (result.data.success) {
					alert("Traden togs bort")
					console.log("success")
					setSelectedTrade(null)
				} else {
					alert("Något gick fel, kontakta admin")
					console.log("fail")
				}
			} else {
				return
			}
		} else {
			if (window.confirm("Är du säker på att du vill ta bort denna händelse?" + "\n\n" + event.timestamp.replaceAll('T', ' ').replaceAll('Z', ' ').slice(0, -4) + '\n\nInfo:\n\n' + event.info)) {
				const result = await deleteEvent(event.id)
				console.log(result)
				if (result.data.success) {
					alert("Händelsen togs bort")
					console.log("success")
					setSelectedTrade(null)
				} else {
					alert("Något gick fel, kontakta admin")
					console.log("fail")
				}
			} else {
				return
			}
		}
	} catch (e) {
		console.error(e)
	}
}

const handleCreateUpdate = async (values, comment, selectedTrade, setSelectedTrade) => {
	console.log("attempting to create update with values:")
	console.log({ values, comment })
	try {
		const errorValues = [];

		if (values.sl && !isNumericString(values.sl)) {
			errorValues.push("SL")
		}
		if (values.tp1 && !isNumericString(values.tp1)) {
			errorValues.push("TP1")
		}
		if (values.tp2 && !isNumericString(values.tp2)) {
			errorValues.push("TP2")
		}
		if (values.tp3 && !isNumericString(values.tp3)) {
			errorValues.push("TP3")
		}

		if (errorValues.length > 0) {
			alert("Följande fält innehåller felaktiga värden, ex. bokstäver: \n" + errorValues.join("\n"))
			return
		}

		const result = await changeTrade({
			"id": selectedTrade.id,
			"sl": values.sl ? String(values.sl).replaceAll(',', '.') : null,
			"tp1": values.tp1 ? String(values.tp1).replaceAll(',', '.') : null,
			"tp2": values.tp2 ? String(values.tp2).replaceAll(',', '.') : null,
			"tp3": values.tp3 ? String(values.tp3).replaceAll(',', '.') : null,
			"info": comment ? comment : null,
		})
		console.log(result)
		if (result.status === 200) {
			alert("Händelse skickad")
			console.log("success")
			setSelectedTrade(null)
		} else {
			alert("Något gick fel, kontakta admin")
		}
	} catch (e) {
		console.error(e)
	}
}

const handleCreateClosing = async (values, comment, selectedTrade, setSelectedTrade) => {
	console.log('attempting to create closing with values:');
	console.log({ values, comment });
	try {
		const requiredMissingValues = [];
		const errorValues = [];

		if (values.closeAll) {
			values.size = 9999;
		}

		if (values.size === null) {
			requiredMissingValues.push("Size")
		}
		if (values.price === null) {
			requiredMissingValues.push("Pris")
		}

		if (!isNumericString(values.size)) {
			errorValues.push("Size")
		}
		if (!isNumericString(values.price)) {
			errorValues.push("Pris")
		}

		if (errorValues.length > 0) {
			alert("Följande fält innehåller felaktiga värden, ex. bokstäver: \n" + errorValues.join("\n"))
			return
		}
		if (requiredMissingValues.length > 0) {
			alert("Följande fält måste fyllas i: \n" + requiredMissingValues.join("\n"))
			return
		}

		const closeObj = {
			"id": selectedTrade.id,
			"price": String(values.price).replaceAll(',', '.'),
			"size": String(values.size).replaceAll(',', '.'),
			"info": comment ? comment : null,
		}

		if (values.closeAll) {
			delete closeObj.size;
		}

		const result = await closeTrade(closeObj)
		console.log(result)
		if (result.status === 200) {
			alert("Avslut skickat")
			console.log("success")
			setSelectedTrade(null)
		} else {
			alert("Något gick fel, kontakta admin")
		}
	} catch (e) {
		console.error(e)
	}
}

const handleCreateFill = async (values, comment, selectedTrade, setSelectedTrade) => {
	console.log('attempting to create fill with values:');
	console.log({ values, comment });
	try {
		const requiredMissingValues = [];
		const errorValues = [];

		if (values.size === null) {
			requiredMissingValues.push("Size")
		}
		if (values.price === null) {
			requiredMissingValues.push("Pris")
		}

		if (!isNumericString(values.size)) {
			errorValues.push("Size")
		}
		if (!isNumericString(values.price)) {
			errorValues.push("Pris")
		}

		if (errorValues.length > 0) {
			alert("Följande fält innehåller felaktiga värden, ex. bokstäver: \n" + errorValues.join("\n"))
			return
		}
		if (requiredMissingValues.length > 0) {
			alert("Följande fält måste fyllas i: \n" + requiredMissingValues.join("\n"))
			return
		}

		const result = await fillTrade({
			"id": selectedTrade.id,
			"price": String(values.price).replaceAll(',', '.'),
			"size": String(values.size).replaceAll(',', '.'),
			"info": comment ? comment : null,
		})
		console.log(result)
		if (result.status === 200) {
			alert("Påfyllning skickad")
			console.log("success")
			setSelectedTrade(null)
		} else {
			alert("Något gick fel, kontakta admin")
		}
	} catch (e) {
		console.error(e)
	}
}

//  ███████ ██    ██ ███    ██  ██████ 
//  ██      ██    ██ ████   ██ ██      
//  █████   ██    ██ ██ ██  ██ ██      
//  ██      ██    ██ ██  ██ ██ ██      
//  ██       ██████  ██   ████  ██████ 

export default function DetailsMenu(props) {
	const [tableHeight, setTableHeight] = useState(400); // Default height
	const { isFilterMenuOpen, setIsFilterMenuOpen, isEditEventMenuOpen, setIsEditEventMenuOpen } = useContext(MenuContext);
	const { trades, setTrades, selectedTrade, setSelectedTrade, selectedEvent, setSelectedEvent, userFollowedTrades, setUserFollowedTrades } = useContext(TradesContext);

	const [updatedValues, setUpdatedValues] = useState({
		sl: null,
		tp1: null,
		tp2: null,
		tp3: null,
	});
	const [updatedComment, setUpdatedComment] = useState("");

	const [closingValues, setClosingValues] = useState({
		price: null,
		size: null,
		closeAll: null,
	});
	const [closingComment, setClosingComment] = useState("");

	const [fillValues, setFillValues] = useState({
		price: null,
		size: null,
	});
	const [fillComment, setFillComment] = useState("");

	const [avanzaChartData, setAvanzaChartData] = useState([]);

	useEffect(() => {
		// Calculate available height between header and footer
		const headerHeight = document.getElementById('header').offsetHeight;
		const footerHeight = document.getElementById('footer').offsetHeight;
		var filterMenuHeight = 0;
		if (isFilterMenuOpen) {
			filterMenuHeight = 68; //document.getElementById('filter-menu').offsetHeight; //TODO: dumt system, men funkar, hitta height på filtermenyn manuellt
		}
		const availableHeightTmp = window.innerHeight - headerHeight - footerHeight - filterMenuHeight;

		// Set the height of the table dynamically
		setTableHeight(availableHeightTmp);
	}, [isFilterMenuOpen]);

	useEffect(() => {
		if (selectedTrade) {
			setUpdatedValues({
				sl: selectedTrade.sl,
				tp1: selectedTrade.tp1,
				tp2: selectedTrade.tp2,
				tp3: selectedTrade.tp3
			});
		}
	}, [selectedTrade]);

	useEffect(() => {
		console.log("selected trade changed")
		if (selectedTrade?.info?.avanzaSchemeLink) {
			try {
				const orderBookId = selectedTrade.info.avanzaSchemeLink.split("/")[3].split("?")[0]
				const timeSinceTradeOpen = Date.now() / 1000 - (Date.parse(selectedTrade.timestamp)) / 1000
				var timePeriod = "today";
				var resolution = "five_minutes"
				if (timeSinceTradeOpen > 60 * 60 * 24 * 30) {
					timePeriod = "one_month";
					resolution = "hour"
				} else if (timeSinceTradeOpen > 60 * 60 * 24 * 1) {
					timePeriod = "one_week";
					resolution = "ten_minutes"
				}
				getAvanzaChartDataViaProxy(orderBookId, timePeriod, resolution, setAvanzaChartData);
			} catch (e) {
				setAvanzaChartData([]);
			}
		} else {
			setAvanzaChartData([]);
		}
	}, [JSON.stringify(selectedTrade)]);

	const handleGetLivePrice = async (symbol, bidAskSpread, funcSetter, funcSetterProp) => {
		console.log("Attempting to get live price")
		//console.log('funcSetter: ', funcSetter);
		try {
			const price = await getLivePriceOnSearchedSymbol(symbol);
			//console.log("got price from network: ", price)
			if (price[bidAskSpread]) {
				funcSetter((prev) => { return { ...prev, [funcSetterProp]: price[bidAskSpread] } });
			} else {
				funcSetter((prev) => { return { ...prev, [funcSetterProp]: 0 } });
			}
		} catch (e) {
			console.error(e)
			funcSetter((prev) => { return { ...prev, [funcSetterProp]: 0 } });
		}
	}

	const handleFollowTrade = async () => {
		const isFollowed = userFollowedTrades.includes(selectedTrade.id);
		const id = selectedTrade.id;
		if (isFollowed) {
			console.log("Attempting to unfollow trade")
			const success = await unfollowTrade(id)
			if (success) {
				setUserFollowedTrades(userFollowedTrades.filter((tradeId) => tradeId !== id))
			}
		} else {
			console.log("Attempting to follow trade")
			const success = await followTrade(id)
			if (success) {
				setUserFollowedTrades([...userFollowedTrades, id])
			}
		}
	}

	const detailRows1 = [
		{
			title: "Öppnad:", value: String(new Date(selectedTrade.events[selectedTrade.events.length - 1].timestamp).toLocaleString()).replace(',', '')
		},
		{
			title: "ISIN:", value: selectedTrade.isin
		},
		{
			title: "U.liggande:", value: selectedTrade.info?.name
		},
		{
			title: "Status:", value: translateStatusToText(selectedTrade.status)
		},
		{
			title: "Trader:", value: selectedTrade.user?.username
		},
		{
			title: "Typ:", value: translateCategoryToDaySwing(selectedTrade.category)
		},
		{
			title: "Häv:", value: selectedTrade.leverage
		}
	]

	const detailRows2 = [
		{
			title: "Ticker:", value: selectedTrade.ticker
		},
		{
			title: "Entry:", value: selectedTrade.price
		},
		{
			title: "SL:", value: selectedTrade.sl
		},
		{
			title: "TP1:", value: selectedTrade.tp1
		},
		{
			title: "TP2:", value: selectedTrade.tp2
		},
		{
			title: "TP3:", value: selectedTrade.tp3
		},
		{
			title: "Size:", value: selectedTrade.size_left + "/" + selectedTrade.size
		}
	]

	return (
		<TableContainer sx={{ width: "100%", maxHeight: tableHeight - 4, scrollbarWidth: "thin" }} className="small-scrollbar">
			<Table>
				<TableHead>
					<TableRow>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
							{translateCategoryToDaySwing(selectedTrade.category)}trade {selectedTrade?.ticker}
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
							<IconButton
								aria-label="modify row"
								size="small"
								onClick={() => handleFollowTrade(selectedTrade.id)}
							>
								{
									userFollowedTrades.includes(selectedTrade.id) ?
										<NotificationsOffIcon />
										:
										<NotificationsIcon />
								}
							</IconButton>
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
							<IconButton
								aria-label="modify row"
								size="small"
								onClick={() => setSelectedTrade(null)}
							>
								<CloseIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				</TableHead>
			</Table>
			<TableContainer sx={{ flex: 1, display: "flex" }}>
				<Table sx={{ flex: 1 }}>
					<TableHead>
						<TableRow>
							<TableCell colSpan={2} sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
								Detaljer
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							detailRows1.map((row, index) => {
								return (
									<TableRow>
										<TableCell sx={{ borderRight: "1px solid #515151", textAlign: "right", width: "35%" }}>
											{row.title}
										</TableCell>
										<TableCell>
											{row.value}
										</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
				<Table sx={{ flex: 1, borderLeft: "1px solid #515151" }}>
					<TableHead>
						<TableRow>
							<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)", width: "45%" }}>
								Flytta nivåer/skicka info
							</TableCell>
							<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
								<FormControl fullWidth>
									<Button
										variant="contained"
										disableElevation
										size="small"
										endIcon={<SendIcon />}
										onClick={() => { handleCreateUpdate(updatedValues, updatedComment, selectedTrade, setSelectedTrade) }}
									>
										Skicka
									</Button>
								</FormControl>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>
								SL:
							</TableCell>
							<TableCell style={{ height: 20 }}>
								<Input
									fullWidth
									sx={{ fontSize: "0.875rem", height: 19 }}
									placeholder={selectedTrade.sl}
									value={updatedValues.sl}
									onChange={(e) => setUpdatedValues({ ...updatedValues, sl: e.target.value })}
									endAdornment={
										<Button
											variant="contained"
											sx={{ marginTop: 0, height: 19 }}
											onClick={() => { setUpdatedValues({ ...updatedValues, sl: selectedTrade.price }) }}
										>
											<span style={{ fontSize: "0.575rem" }}>BE</span>
										</Button>
									}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								TP1:
							</TableCell>
							<TableCell style={{ height: 20 }}>
								<Input
									fullWidth
									sx={{ fontSize: "0.875rem", height: 19 }}
									placeholder={selectedTrade.tp1 ?? "TP1"}
									value={updatedValues.tp1}
									onChange={(e) => setUpdatedValues({ ...updatedValues, tp1: e.target.value })}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								TP2:
							</TableCell>
							<TableCell style={{ height: 20 }}>
								<Input
									fullWidth
									sx={{ fontSize: "0.875rem", height: 19 }}
									placeholder={selectedTrade.tp2 ?? "TP2"}
									value={updatedValues.tp2}
									onChange={(e) => setUpdatedValues({ ...updatedValues, tp2: e.target.value })}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								TP3:
							</TableCell>
							<TableCell style={{ height: 20 }}>
								<Input
									fullWidth
									sx={{ fontSize: "0.875rem", height: 19 }}
									placeholder={selectedTrade.tp3 ?? "TP3"}
									value={updatedValues.tp3}
									onChange={(e) => setUpdatedValues({ ...updatedValues, tp3: e.target.value })}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell colSpan={2} rowSpan={3} style={{ padding: 0, margin: 0 }}>
								<FormControl fullWidth>
									<TextField
										id="outlined-multiline-static"
										margin="none"
										multiline
										rows={3}
										placeholder="Kommentar"
										value={updatedComment}
										onChange={(e) => setUpdatedComment(e.target.value)}
									/>
								</FormControl>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			{/* 2 */}
			<TableContainer sx={{ flex: 1, display: "flex" }}>
				<Table sx={{ flex: 1 }}>
					<TableBody sx={{ borderTop: "1px solid var(--w3)" }}>
						{
							detailRows2.map((row, index) => {
								return (
									<TableRow>
										<TableCell sx={{ borderRight: "1px solid #515151", textAlign: "right", width: "35%" }}>
											{row.title}
										</TableCell>
										<TableCell>
											{row.value}
										</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
				<Table sx={{ flex: 1, borderLeft: "1px solid #515151" }}>
					<TableHead>
						<TableRow>
							<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)", width: "45%" }}>
								Gör påfyllnad
							</TableCell>
							<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
								<FormControl fullWidth>
									<Button
										style={{ backgroundColor: "rgba(0, 255, 0, 0.87)" }}
										variant="contained"
										disableElevation
										size="small"
										endIcon={<SendIcon />}
										onClick={() => { handleCreateFill(fillValues, fillComment, selectedTrade, setSelectedTrade) }}
									>
										Fyll på
									</Button>
								</FormControl>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>
								Pris:
							</TableCell>
							<TableCell style={{ height: 20 }}>
								<Input
									fullWidth
									sx={{ fontSize: "0.875rem", height: 19 }}
									placeholder={"Påfyllnadspris"}
									value={fillValues.price}
									onChange={(e) => setFillValues({ ...fillValues, price: e.target.value })}
									endAdornment={
										<Button
											variant="contained"
											sx={{ marginTop: 0, height: 19 }}
											onClick={() => { handleGetLivePrice(selectedTrade.ticker, "bid", setFillValues, "price") }}
										>
											<span style={{ fontSize: "0.575rem" }}>Hämta live</span>
										</Button>
									}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								Ek att fylla:
							</TableCell>
							<TableCell style={{ height: 20 }}>
								<Input
									fullWidth
									sx={{ fontSize: "0.875rem", height: 19 }}
									placeholder={"EK att fylla"}
									value={fillValues.size}
									onChange={(e) => setFillValues({ ...fillValues, size: e.target.value })}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell colSpan={2} rowSpan={3} style={{ padding: 0, margin: 0 }}>
								<FormControl fullWidth>
									<TextField
										id="outlined-multiline-static"
										margin="none"
										multiline
										rows={3}
										placeholder="Kommentar"
										value={fillComment}
										onChange={(e) => setFillComment(e.target.value)}
									/>
								</FormControl>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<TableContainer sx={{ flex: 1, display: "flex" }}>
				<Table sx={{ flex: 1 }}>
					<TableBody sx={{ borderTop: "1px solid var(--w3)" }}>
						<TableRow>
							<TableCell colSpan={2} sx={{ borderRight: "1px solid #515151", width: "35%", backgroundColor: "rgb(27, 30, 38)" }}>
								Händelser
							</TableCell>
						</TableRow>
						{
							selectedTrade.events?.map((event, index) => {
								return (
									<TableRow>
										<TableCell sx={{ borderRight: "1px solid #515151", textAlign: "right", width: "35%" }}>
											{translateTypeToText(event.type)}
										</TableCell>
										<Tooltip placement='top' title={String(event.timestamp).substring(11, 19) + " | " + String(event.info ?? "-")} arrow>
											<TableCell>
												<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
													<p style={{ flex: 1, margin: 0 }}>{convertDateToRelevant(event.timestamp)}</p>
													<p style={{ flex: 2, margin: 0, textWrap: "nowrap", whiteSpace: "none", overflow: "hidden", textOverflow: "ellipsis" }}>{toShortenedText(String(event.info ?? "-"))}</p>
													<span style={{ flex: 0.4, margin: 0, justifyContent: "end", display: "flex", width: 20 }}>
														<IconButton
															sx={{ padding: 0, margin: 0, height: 19, width: 19, marginRight: "1px" }}
															aria-label="modify row"
															size="small"
															onClick={() => { handleEditEvent(event, setSelectedEvent, setSelectedTrade, setIsEditEventMenuOpen) }}
														>
															<TuneIcon />
														</IconButton>
														<IconButton
															sx={{ padding: 0, margin: 0, height: 19, width: 19 }}
															aria-label="modify row"
															size="small"
															onClick={() => { handleDeleteEvent(event, setSelectedTrade) }}
														>
															<CloseIcon />
														</IconButton>
													</span>
												</div>
											</TableCell>
										</Tooltip>
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
				<Table sx={{ flex: 1, borderLeft: "1px solid #515151" }}>
					<TableHead>
						<TableRow>
							<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)", width: "45%" }}>
								Gör avslut
							</TableCell>
							<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
								<FormControl fullWidth>
									<Button
										style={{ backgroundColor: "rgba(255, 0, 0, 0.87)" }}
										variant="contained"
										disableElevation
										size="small"
										endIcon={<SendIcon />}
										onClick={() => { handleCreateClosing(closingValues, closingComment, selectedTrade, setSelectedTrade) }}
									>
										Skicka avslut
									</Button>
								</FormControl>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>
								Pris:
							</TableCell>
							<TableCell style={{ height: 20, }}>
								<Input
									fullWidth
									sx={{ fontSize: "0.875rem", height: 19 }}
									placeholder={"Stängningspris"}
									value={closingValues.price}
									onChange={(e) => setClosingValues({ ...closingValues, price: e.target.value })}
									endAdornment={
										<Button
											variant="contained"
											sx={{ marginTop: 0, height: 19 }}
											onClick={() => { handleGetLivePrice(selectedTrade.ticker, "bid", setClosingValues, "price") }}
										>
											<span style={{ fontSize: "0.575rem" }}>Hämta live</span>
										</Button>
									}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								Ek att stänga:
							</TableCell>
							<TableCell style={{ height: 20 }}>
								<Input
									fullWidth
									sx={{ fontSize: "0.875rem", height: 19 }}
									placeholder={"Antal att stänga"}
									value={closingValues.size}
									onChange={(e) => setClosingValues({ ...closingValues, size: e.target.value })}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								Stäng helt?:
							</TableCell>
							<TableCell style={{ height: 20 }}>
								<Checkbox
									size="small"
									sx={{
										'&:hover': { bgcolor: 'transparent' },
										height: 19
									}}
									checked={closingValues.closeAll}
									onChange={(e) => setClosingValues({ ...closingValues, closeAll: e.target.checked })}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell colSpan={2} rowSpan={3} style={{ padding: 0, margin: 0 }}>
								<FormControl fullWidth>
									<TextField
										id="outlined-multiline-static"
										margin="none"
										multiline
										rows={3}
										placeholder="Kommentar"
										value={closingComment}
										onChange={(e) => setClosingComment(e.target.value)}
									/>
								</FormControl>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<TableContainer sx={{ flex: 1, display: "flex", overflow: "hidden" }}>
				<Table sx={{ flex: 1 }}>
					<TableHead>
						<TableRow>
							<TableCell colSpan={2} sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
								<div style={{ display: "flex", flexDirection: "row", justifyContent: "start", gap: 4 }}>
									<p style={{ margin: 0, padding: 0 }}>Länk till instrumentet:</p>
									<a href={selectedTrade.info?.avanzaLink ?? "#"} style={{ margin: 0, padding: 0, color: selectedTrade.info?.avanzaLink ? "rgba(255,255,255,0.87)" : "rgba(255,255,255,0.38)" }}>Avanza</a>
									<a href={selectedTrade.info?.nordnetLink ?? "#"} style={{ margin: 0, padding: 0, color: selectedTrade.info?.nordnetLink ? "rgba(255,255,255,0.87)" : "rgba(255,255,255,0.38)" }}>Nordnet</a>
								</div>
							</TableCell>
						</TableRow>
					</TableHead>
					{avanzaChartData.length > 10 ?
						<LinechartComponent data={avanzaChartData} levels={[
							{ name: "Entry", price: selectedTrade.price, color: "rgba(255,255,0,0.87)" },
							{ name: "SL", price: selectedTrade.sl, color: "rgba(255,0,0,0.87)" },
							{ name: "TP1", price: selectedTrade.tp1, color: "rgba(255,255,255,0.87)" },
							{ name: "TP2", price: selectedTrade.tp2, color: "rgba(255,255,255,0.87)" },
							{ name: "TP3", price: selectedTrade.tp3, color: "rgba(255,255,255,0.87)" },
						]}
							events={selectedTrade.events?.map(event => {
								return {
									id: event.id,
									time: new Date(event.timestamp),
									position: event.type == "close" ? "aboveBar" : "belowBar",
									shape: event.type == "close" ? "arrowDown" : event.type == "open" || event.type == "fill" ? "arrowUp" : "circle",
									color: event.type == "close" ? "rgba(255,0,0,0.87)" : event.type == "open" || event.type == "fill" ? "rgba(0,255,0,0.87)" : "rgba(255,255,255,0.87)",
									text: event.type == "close" ? "Close" : event.type == "open" ? "Open" : event.type == "fill" ? "Fill" : "",
									size: 1
								}
							})}
						></LinechartComponent>
						: null
					}
				</Table>
			</TableContainer>
		</TableContainer>
	);
}
