import { useState, createContext, useEffect, useLayoutEffect } from 'react';
import './App.css';
import Login from './components/Login';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, useTheme, createTheme } from '@mui/material/styles';
import Header from './components/Header';
import Footer from './components/Footer';
import MainOverview from './components/MainOverview';
import HeaderMobile from './components/HeaderMobile';
import MainOverviewMobile from './components/MainOverviewMobile';
import { getAllTrades, getFollowedTrades } from './lib/network';
import axios, { all } from "axios";
import Cookies from 'universal-cookie';
import { socket } from './lib/socket';

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      ..."rgba(255,255,255,0.87)",
      ...(mode === 'dark' && {
        main: "rgba(255,255,255,0.87)",
      }),
    },
    ...(mode === 'dark' && {
      background: {
        default: "#1e222d",
        paper: "#1e222d",
      },
    }),
    text: {
      ...(mode === 'light'
        ? {
          primary: "rgba(0,0,0,0.87)",
          secondary: "rgba(0,0,0,0.6)",
        }
        : {
          primary: 'rgba(255,255,255,0.87)',
          secondary: "rgba(255,255,255,0.6)",
        }),
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            borderRadius: 0,
            padding: "0px",
            margin: 0,
            lineHeight: "17px",
            fontSize: "0.875rem",
            '& .MuiFilledInput-root': {
              borderRadius: 0,
              padding: "4px",
              margin: 0,
              lineHeight: "17px",
              fontSize: "0.875rem",
              '&:hover': {
                '& .MuiFilledInput-root': {
                  borderRadius: 0,
                  padding: "4px",
                  margin: 0,
                  lineHeight: "17px",
                  fontSize: "0.875rem",
                },
              },
              '&.Mui-focused': {
                '& .MuiFilledInput-root': {
                  borderRadius: 0,
                  padding: "4px",
                  margin: 0,
                  lineHeight: "17px",
                  fontSize: "0.875rem",
                },
              },
            },
          },
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        disableFocusRipple: true,
        disableTouchRipple: true,
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            padding: 1,
            margin: 1,
            lineHeight: 1.5,
            fontSize: "0.875rem",
          },
        },
      ],
    },
  },
});

const darkModeTheme = createTheme(getDesignTokens('dark'));

export const AppContext = createContext();
export const UserContext = createContext();
export const TradesContext = createContext();
export const MenuContext = createContext();
export const FilterContext = createContext();

//  ███████ ██    ██ ███    ██  ██████ 
//  ██      ██    ██ ████   ██ ██      
//  █████   ██    ██ ██ ██  ██ ██      
//  ██      ██    ██ ██  ██ ██ ██      
//  ██       ██████  ██   ████  ██████ 

export default function App() {
  const cookies = new Cookies();

  const [isMobileDevice, setIsMobileDevice] = useState(cookies.get("ckIsMobileDevice") ?? window.innerWidth < 768);

  const [isLoggedIn, setIsLoggedIn] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [socketIsConnected, setSocketIsConnected] = useState(socket.connected);
  const [numTradesAllowed, setNumTradesAllowed] = useState(3);

  const [selectedTrade, setSelectedTrade] = useState();

  const [fetchedTrades, setFetchedTrades] = useState([]);
  const [userFollowedTrades, setUserFollowedTrades] = useState([]);
  const [trades, setTrades] = useState([]);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [allInstruments, setAllInstruments] = useState();
  const [currentlyUsedEkTotalDay, setCurrentlyUsedEkTotalDay] = useState(0);
  const [currentlyUsedEkTotalSwing, setCurrentlyUsedEkTotalSwing] = useState(0);
  const [allTraders, setAllTraders] = useState();
  const [tradeFilter, setTradeFilter] = useState({
    "trader": {
      "type": "string",
      "value": []
    },
    "open": { "type": "boolean", "value": true },
    "closed": { "type": "boolean", "value": false },
    "ticker": {
      "type": "string",
      "value": []
    },
    "long": { "type": "boolean", "value": true },
    "short": { "type": "boolean", "value": true },
    "swing": { "type": "boolean", "value": true },
    "day": { "type": "boolean", "value": true },
  });

  const [isNewTradeMenuOpen, setIsNewTradeMenuOpen] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState();
  const [isEditEventMenuOpen, setIsEditEventMenuOpen] = useState(false);

  const [tradesLoadedMultiplier, setTradesLoadedMultiplier] = useState(1);

  //TODO: exportera till xlsx

  // om användaren är på en mobil enhet
  useEffect(() => {
    console.log("isMobileDevice: " + isMobileDevice);
  }, [isMobileDevice]);

  useLayoutEffect(() => {
    if (!isLoggedIn) {
      console.log('checking cookies:');
      console.log();
      const loginCk = cookies.get("ckIsLoggedIn");
      const jwtCk = cookies.get("ckJwt");
      const userCk = cookies.get("ckCurrentUser");
      if (loginCk && loginCk == true && jwtCk && userCk) {
        console.log('cookies found, logging in');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwtCk;
        setCurrentUser(userCk);
        setIsLoggedIn(true);
      }
    }
  }, []);

  useEffect(() => {
    console.log("Current user is now: " + currentUser);
  }, [currentUser]);

  useEffect(() => {
    console.log('isLoggedIn changed to: ' + isLoggedIn);
    if (isLoggedIn) {
      async function getTrades() {
        const tradeData = await getAllTrades();
        setFetchedTrades(tradeData);
        console.log(tradeData);
      }
      async function getFollowed() {
        const followedIds = await getFollowedTrades();
        setUserFollowedTrades(followedIds);
        console.log(followedIds);
      }
      getTrades();
      getFollowed();
      socket.connect();
    } else {
      socket.disconnect();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setTrades(fetchedTrades);
    var fetchedInstruments = [];
    var fetchedTraders = [];
    fetchedTrades.forEach((trade) => {
      if (trade.ticker) {
        if (!fetchedInstruments.includes(trade.ticker)) {
          fetchedInstruments.push(trade.ticker);
        }
      }
      if (trade.user) {
        if (!fetchedTraders.find((existing) => existing.id == trade.user.id)) {
          fetchedTraders.push({ name: trade.user.username, id: trade.user.id });
        }
      }
    });
    setAllInstruments(fetchedInstruments);
    setAllTraders(fetchedTraders);
    setTradeFilter((prev) => {
      return {
        ...prev,
        "trader": {
          ...prev.trader,
          //"value": fetchedTraders 
        },
        "ticker": {
          ...prev.ticker,
          //"value": fetchedInstruments
        }
      }
    })

    if (fetchedTrades?.length > 0) {
      var ekUsedTmp = fetchedTrades.reduce((accumulator, trade) => {
        const retVal = trade.category.includes("daytrades") ? trade.size_left : 0;
        return accumulator + retVal;
      }, 0);
      console.log("ekUsedTmp: day " + ekUsedTmp);
      setCurrentlyUsedEkTotalDay(ekUsedTmp);
    }
    if (fetchedTrades?.length > 0) {
      var ekUsedTmp = fetchedTrades.reduce((accumulator, trade) => {
        const retVal = trade.category.includes("Swings") ? trade.size_left : 0;
        return accumulator + retVal
      }, 0);
      console.log("ekUsedTmp: swing " + ekUsedTmp);
      setCurrentlyUsedEkTotalSwing(ekUsedTmp);
    }
  }, [fetchedTrades]);

  //  ███████ ██ ██      ████████ ███████ ██████  
  //  ██      ██ ██         ██    ██      ██   ██ 
  //  █████   ██ ██         ██    █████   ██████  
  //  ██      ██ ██         ██    ██      ██   ██ 
  //  ██      ██ ███████    ██    ███████ ██   ██ 

  useEffect(() => {
    console.log("Fetched trades or trade filter changed, filtering trades...")
    if (fetchedTrades.length > 0) {
      setTrades((prev) => {
        var filteredTrades = [...fetchedTrades];

        console.log("tradeFilter:")
        console.log(tradeFilter)

        if (tradeFilter.trader.value.length > 0) {
          filteredTrades = filteredTrades.filter((trade) => {
            if (tradeFilter.trader.value.find((person) => person.id == trade?.user?.id)) {
              return true;
            }
            return false;
          });
        }
        if (tradeFilter.ticker.value.length > 0) {
          filteredTrades = filteredTrades.filter((trade) => {
            if (tradeFilter.ticker.value.includes(trade?.ticker)) {
              return true;
            }
            return false;
          });
        }
        filteredTrades = filteredTrades.filter((trade) => {
          if (!tradeFilter.open.value && trade.status == "open") {
            return false;
          }
          return true;
        });
        filteredTrades = filteredTrades.filter((trade) => {
          if (!tradeFilter.closed.value && trade.status == "closed") {
            return false;
          }
          return true;
        });
        filteredTrades = filteredTrades.filter((trade) => {
          if (!tradeFilter.long.value && trade.direction == "long") {
            return false;
          }
          return true;
        });
        filteredTrades = filteredTrades.filter((trade) => {
          if (!tradeFilter.short.value && trade.direction == "short") {
            return false;
          }
          return true;
        });
        filteredTrades = filteredTrades.filter((trade) => {
          if (!tradeFilter.swing.value && trade.category.includes("swing")) {
            return false;
          }
          return true;
        });
        filteredTrades = filteredTrades.filter((trade) => {
          if (!tradeFilter.day.value && trade.category.includes("day")) {
            return false;
          }
          return true;
        });

        filteredTrades.sort((a, b) => a.events[a.events.length - 1].timestamp - b.events[b.events.length - 1].timestamp < 0 ? 1 : -1);
        if (filteredTrades.length > 0) {
          if (filteredTrades[0].id < filteredTrades[filteredTrades.length - 1].id) {
            console.log('id of first: ' + filteredTrades[0].id + ' is less than id of last: ' + filteredTrades[filteredTrades.length - 1].id + ', reversing');
            filteredTrades.reverse();
          }
          return filteredTrades;
        } else {
          return [];
        }
      });
    }
  }, [tradeFilter, fetchedTrades]);

  //  ███████  ██████   ██████ ██   ██ ███████ ████████ 
  //  ██      ██    ██ ██      ██  ██  ██         ██    
  //  ███████ ██    ██ ██      █████   █████      ██    
  //       ██ ██    ██ ██      ██  ██  ██         ██    
  //  ███████  ██████   ██████ ██   ██ ███████    ██    

  useEffect(() => {
    function onConnect() {
      console.log("Socket connected")
      const jwtCk = cookies.get("ckJwt");
      console.log("sending authentication to websocket (App.js): ", jwtCk)
      socket.emit('authentication', { token: jwtCk });
    }

    function onDisconnect() {
      setSocketIsConnected(false);
      console.log("Socket disconnected")
    }

    function onAuthenticated() {
      setSocketIsConnected(true);
      console.log('Ansluten till Socket.IO med autentisering');
    }

    function onUnauthorized(err) {
      console.log('Icke-autentiserad anslutning till Socket.IO:', err);
    }

    function onTrade(data) {
      //console.log("received new trade: ")
      //console.log(JSON.stringify(data.data.ticker, null, 4))
      //console.log("time of trade: ")
      const tradeTime = new Date(data.data.timestamp)
      //console.log(tradeTime.toISOString())
      //console.log("Current time: ")
      //console.log(new Date().toISOString())

      try {
        //console.log("full trade:")
        //console.log(JSON.stringify(data.data, null, 4))
        //console.log("appending new trade to allTrades")
        const events = data.data.events

        data.data.events = events.reverse()

        setFetchedTrades(prev => {
          let existingIds = prev.map(t => t.id)
          if (!existingIds.includes(data.data.id)) {
            console.log("trade does not exist already, adding")
            return ([data.data, ...prev])
          } else {
            //If trade already exists, replace old trade with new
            console.log("trade already exists, replacing old trade with new")
            const newTrades = [...prev]
            const existingTradeIdx = prev.findIndex(t => t.id == data.data.id)
            newTrades[existingTradeIdx] = data.data
            //If same trade is currently selected, replace selected trade with new
            setSelectedTrade((prev) => {
              if (prev && prev.id == data.data.id) {
                return data.data
              } else {
                return prev
              }
            })
            return (newTrades)
          }
        });
      } catch (e) {
        console.warn(e)
      }
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('authenticated', onAuthenticated);
    socket.on('unauthorized', onUnauthorized);
    socket.on('trade', onTrade);
    //socket.connect();

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('authenticated', onAuthenticated);
      socket.off('unauthorized', onUnauthorized);
      socket.off('trade', onTrade);
    };
  }, []);

  return (
    <AppContext.Provider value={{ isMobileDevice, setIsMobileDevice, socketIsConnected, setSocketIsConnected }}>
      <UserContext.Provider value={{ isLoggedIn, setIsLoggedIn, numTradesAllowed, setNumTradesAllowed, cookies, currentUser, setCurrentUser }}>
        <MenuContext.Provider value={{ isFilterMenuOpen, setIsFilterMenuOpen, isNewTradeMenuOpen, setIsNewTradeMenuOpen, isEditEventMenuOpen, setIsEditEventMenuOpen }}>
          <TradesContext.Provider value={{ trades, setTrades, fetchedTrades, setFetchedTrades, selectedTrade, setSelectedTrade, tradesLoadedMultiplier, setTradesLoadedMultiplier, currentlyUsedEkTotalDay, currentlyUsedEkTotalSwing, selectedEvent, setSelectedEvent, userFollowedTrades, setUserFollowedTrades }}>
            <FilterContext.Provider value={{ tradeFilter, setTradeFilter, allInstruments, allTraders }}>
              <ThemeProvider theme={darkModeTheme}>
                <CssBaseline />
                {
                  isMobileDevice ?
                    (
                      <div className='main'>
                        <div className='header-mobile' id='header'>
                          <HeaderMobile />
                        </div>
                        <div className='body-mobile'>
                          {!isLoggedIn ? <Login /> : <MainOverviewMobile />}
                        </div>
                      </div>
                    )
                    :
                    (
                      <div className='main'>
                        <div className='header' id='header'>
                          <Header />
                        </div>
                        <div className='body'>
                          {!isLoggedIn ? <Login /> : <MainOverview />}
                        </div>
                        <div className='footer' id='footer'>
                          <Footer />
                        </div>
                      </div>
                    )
                }
              </ThemeProvider>
            </FilterContext.Provider>
          </TradesContext.Provider>
        </MenuContext.Provider>
      </UserContext.Provider>
    </AppContext.Provider>
  );
}