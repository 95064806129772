import { Box, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useContext, useEffect, useState } from "react";
import { convertDateToRelevant, convertDayToRecent, dateDifference, translateCategoryToDaySwing, translateStatusToText, translateTypeToText, translateUserIdToName } from "../lib/helpFunc";
import { MenuContext, TradesContext } from "../App";

export default function TradeRow(props) {
	const [isExpanded, setIsExpanded] = useState(false);
	const { selectedTrade, setSelectedTrade, selectedEvent, setSelectedEvent } = useContext(TradesContext);
	const { isNewTradeMenuOpen, setIsNewTradeMenuOpen, isEditEventMenuOpen, setIsEditEventMenuOpen } = useContext(MenuContext);

	const trade = props.trade;

	if (!trade) {
		return <></>;
	}

	return (
		<>
			<TableRow key={trade.id} style={{ backgroundColor: trade.status == "open" ? "#262a35" : "#1e222d", boxShadow: isExpanded ? "1px 1px 0 0 var(--w2) inset, -1px 0 0 0 var(--w2) inset" : "none" }}>
				<TableCell onClick={() => setIsExpanded(!isExpanded)} style={{ border: "none", width: 20, paddingLeft: 10 }}>
					<IconButton
						aria-label="expand row"
						size="small"
					>
						{isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell onClick={() => setIsExpanded(true)} style={{ border: "none" }}>
					<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
						<p style={{ padding: 0, margin: 0 }}>{trade.id}&nbsp;</p>
						<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>{trade.user?.username}&nbsp;</p>
					</div>
				</TableCell>
				<TableCell onClick={() => setIsExpanded(true)} style={{ border: "none" }}>
					<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
						<p style={{ padding: 0, margin: 0 }}>{translateStatusToText(trade.status)}&nbsp;</p>
						<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>{translateCategoryToDaySwing(trade.category)}&nbsp;</p>
					</div>

				</TableCell>
				<TableCell onClick={() => setIsExpanded(true)} style={{ border: "none" }}>
					<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
						<p style={{ padding: 0, margin: 0 }}>{trade.ticker}&nbsp;</p>
						<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>{trade.name}&nbsp;</p>
					</div>
				</TableCell>
				<TableCell onClick={() => setIsExpanded(true)} style={{ border: "none" }}>
					<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
						<p style={{ padding: 0, margin: 0 }}>{convertDateToRelevant(trade.events[trade.events.length - 1].timestamp)}&nbsp;</p>
						<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>{convertDayToRecent(trade.events[trade.events.length - 1].timestamp)}&nbsp;</p>
					</div>
				</TableCell>
				<TableCell style={{ border: "none" }}>
					<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
						<p style={{ padding: 0, margin: 0 }}>{trade.size_left}/{trade.size}&nbsp;</p>
						<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>x{trade.leverage}&nbsp;</p>
					</div>
				</TableCell>
				<TableCell onClick={() => setIsExpanded(true)} style={{ border: "none" }}>
					<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
						<p style={{ padding: 0, margin: 0, color: trade.current_profit ? trade.current_profit > 0 ? "#2272f2" : "#f03c20" : "var(--w3)" }}>{trade.current_profit ? String(trade.current_profit) + "%" : "-"}&nbsp;</p>
						<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>{trade.net_profit ? String(trade.net_profit) + "%" : "-"}&nbsp;</p>
					</div></TableCell>
				<TableCell onClick={() => { setSelectedTrade(trade); setIsNewTradeMenuOpen(false); setIsEditEventMenuOpen(false); setSelectedEvent(false); }} style={{ border: "none" }}>
					<IconButton
						aria-label="modify row"
						size="small">
						<ChevronRightIcon />
					</IconButton>
				</TableCell>
			</TableRow >
			<TableRow key={trade.id + "z"} style={{ backgroundColor: trade.status == "open" ? "#262a35" : "#1e222d", boxShadow: isExpanded ? "0 -1px 0 1px var(--w2) inset" : "none" }}>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
					<Collapse in={isExpanded} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Typography variant="h6" gutterBottom component="div">
								Händelser
							</Typography>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell>Id</TableCell>
										<TableCell>Typ</TableCell>
										<TableCell>Size</TableCell>
										<TableCell>Pris</TableCell>
										<TableCell>Resultat</TableCell>
										<TableCell>Tid</TableCell>
										<TableCell>Info</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{trade.events.map((event, idx) => (
										<TableRow key={"event" + event.id}>
											<TableCell>
												<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
													<p style={{ padding: 0, margin: 0 }}>{idx + 1}&nbsp;</p>
													<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>{event.id}&nbsp;</p>
												</div>
											</TableCell>
											<TableCell>{translateTypeToText(event.type)}</TableCell>
											<TableCell>{event.size}</TableCell>
											<TableCell>{event.price}</TableCell>
											<TableCell>{event.net_profit}</TableCell>
											<TableCell>{convertDateToRelevant(event.timestamp)}</TableCell>
											<TableCell style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: "140px", whiteSpace: 'nowrap' }}>{event.info}</TableCell>
										</TableRow>
									))
									}
								</TableBody>
								<TableRow style={{ backgroundColor: "rgba(255,255,255,0.03)" }}>
									<TableCell colSpan={2}></TableCell>
									<TableCell style={{ width: "90px" }}>
										<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
											<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>Size kvar:</p>
											<p style={{ padding: 0, margin: 0 }}>{trade.size_left}&nbsp;</p>
										</div>
									</TableCell>
									<TableCell>
										<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
											<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>Gav:</p>
											<p style={{ padding: 0, margin: 0 }}>{trade.price}&nbsp;</p>
										</div>
									</TableCell>
									<TableCell>
										<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
											<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>Real. gain</p>
											<p style={{ padding: 0, margin: 0 }}>{trade.net_profit ?? 0}%&nbsp;</p>
										</div>
									</TableCell>
									<TableCell colSpan={1}>
										<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
											<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>Öppettid:</p>
											<p style={{ padding: 0, margin: 0 }}>{dateDifference(trade.events[trade.events.length - 1].timestamp, trade.status == "closed" ? trade.events[0].timestamp : new Date())}&nbsp;</p>
										</div>
									</TableCell>
									<TableCell><a target="_blank" href={trade.info?.avanzaLink}>Länk till Avanza</a></TableCell>
								</TableRow>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}