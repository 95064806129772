import TradesTable from "./TradesTable";
import { useEffect, useState, useContext } from "react";
import { MenuContext, TradesContext } from "../App";
import FilterMenu from "./FilterMenu";
import DetailsMenu from "./DetailsMenu";
import NewTradeMenu from "./NewTradeMenu";
import EditEventMenu from "./EditEventMenu";

export default function MainOverview() {
	const { trades, setTrades, selectedTrade, setSelectedTrade } = useContext(TradesContext);
	const { isFilterMenuOpen, setIsFilterMenuOpen, isNewTradeMenuOpen, setIsNewTradeMenuOpen, isEditEventMenuOpen, setIsEditEventMenuOpen } = useContext(MenuContext);

	return (
		<div className="main-overview-div" style={{ display: "flex", gap: "2px" }}>
			<div className="list">
				{
					isFilterMenuOpen &&
					<div className="filter-menu" id="filter-menu">
						<FilterMenu />
					</div>
				}
				{trades && <TradesTable trades={trades} />}
			</div>
			<div className="details">
				{selectedTrade && <DetailsMenu />}
				{isNewTradeMenuOpen && <NewTradeMenu />}
				{isEditEventMenuOpen && <EditEventMenu />}
			</div>
		</div>
	);
}