import { Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { TradesContext, UserContext } from "../App";

export default function Footer() {
	const { trades, setTrades, fetchedTrades, setFetchedTrades, currentlyUsedEkTotalDay, currentlyUsedEkTotalSwing } = useContext(TradesContext);
	const { isLoggedIn, setIsLoggedIn, numTradesAllowed, setNumTradesAllowed, currentUser, setCurrentUser } = useContext(UserContext);

	const [tradesTakenTodayDay, setTradesTakenTodayDay] = useState();
	const [tradesTakenTodaySwing, setTradesTakenTodaySwing] = useState();
	const [ekUsedDay, setEkUsedSwing] = useState();
	const [activeTrades, setActiveTrades] = useState();
	const [closedTrades, setClosedTrades] = useState();

	useEffect(() => {
		if (fetchedTrades.length > 0) {
			// Calculate trades taken today
			const today = new Date().toISOString().slice(0, 10);
			setTradesTakenTodayDay(fetchedTrades.filter(trade => trade.user.id == currentUser && trade.status == "open" && trade.events[trade.events.length - 1].timestamp.includes(today) && trade.category.includes("daytrades")).length);
			setTradesTakenTodaySwing(fetchedTrades.filter(trade => trade.user.id == currentUser && trade.status == "open" && trade.events[trade.events.length - 1].timestamp.includes(today) && trade.category.includes("Swings")).length);

			setActiveTrades(fetchedTrades.filter(trade => trade.status == "open").length);
			setClosedTrades(fetchedTrades.filter(trade => trade.status == "closed").length);
		}
	}, [fetchedTrades]);

	return (
		<div style={{ display: "flex", flexDirection: "row", gap: "32px" }}>
			<div className="footer-section">
				<Typography variant="p"><span style={{ color: "green" }}>●</span> &nbsp; {isLoggedIn ? "Inloggad" : ""}</Typography>
			</div>
			{
				isLoggedIn &&
				<>
					<div className="footer-section">
						<Typography variant="p">Trades aktiva: Day: {tradesTakenTodayDay ?? "-"}/{numTradesAllowed ?? ""} Swing: {tradesTakenTodaySwing ?? "-"}/{numTradesAllowed ?? ""}</Typography>
					</div>
					<div className="footer-section">
						<Typography variant="p">Använt EK: Day: {currentlyUsedEkTotalDay + "%" ?? "-"} Swing: {currentlyUsedEkTotalSwing + "%" ?? "-"}</Typography>
					</div>
					<div className="footer-section">
						<Typography variant="p">Aktiva trades: {activeTrades ?? "-"}</Typography>
					</div>
					<div className="footer-section">
						<Typography variant="p">Stängda trades: {closedTrades ?? "-"}</Typography>
					</div>
				</>
			}
		</div>
	);
}