import { useEffect, useState, useContext, useLayoutEffect } from "react";
import { MenuContext, TradesContext } from "../App";
import FilterMenuMobile from "./FilterMenuMobile";
import DetailsMenuMobile from "./DetailsMenuMobile";
import NewTradeMenuMobile from "./NewTradeMenuMobile";
import EditEventMenuMobile from "./EditEventMenuMobile";
import TradesTableMobile from "./TradesTableMobile";

export default function MainOverviewMobile() {
	const { trades, setTrades, selectedTrade, setSelectedTrade } = useContext(TradesContext);
	const { isFilterMenuOpen, setIsFilterMenuOpen, isNewTradeMenuOpen, setIsNewTradeMenuOpen, isEditEventMenuOpen, setIsEditEventMenuOpen } = useContext(MenuContext);

	const [mobileMenuOpen, setMobileMenuOpen] = useState(null);

	useLayoutEffect(() => {
		if (!selectedTrade && !isNewTradeMenuOpen && !isEditEventMenuOpen) {
			if (trades) {
				setMobileMenuOpen((<TradesTableMobile trades={trades} />));
			}
		} else if (isEditEventMenuOpen) {
			setMobileMenuOpen((<EditEventMenuMobile />));
		} else if (isNewTradeMenuOpen) {
			setMobileMenuOpen((<NewTradeMenuMobile />));
		} else if (selectedTrade) {
			setMobileMenuOpen((<DetailsMenuMobile />));
		} else {
			setMobileMenuOpen(null);
		}
	}, [selectedTrade, isNewTradeMenuOpen, isEditEventMenuOpen, trades]);

	return (
		<>
			<div className="list">
				{
					isFilterMenuOpen &&
					<div className="filter-menu" id="filter-menu">
						<FilterMenuMobile />
					</div>
				}
			</div>
			<div className="details">
				{
					mobileMenuOpen ? mobileMenuOpen : null
				}
			</div>
		</>
	);
}